// import * as amplitude from '@amplitude/analytics-browser';
import { trpc } from '@/utils/trpc';

export function useLoginGoogleauth0() {
  const { mutateAsync: track } = trpc.useMutation('event.track');

  const loginGoogleauth0Track = () => {
    track({
      event_type: 'Login Googleauth0',
      event_data: { screen: 'Login' },
    });
    //// amplitude.track('Login Googleauth0');
  };
  return {
    loginGoogleauth0Track,
  };
}

// import * as amplitude from '@amplitude/analytics-browser';
import { trpc } from '@/utils/trpc';

export function useLoginContinue() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const loginContinueTrack = () => {
    track({
      event_type: 'Login Continue',
      event_data: { screem: 'login' },
    });
    //// amplitude.track('Login Continue');
  };
  return {
    loginContinueTrack,
  };
}

import { clearAppState } from '@/utils/app';
import { trpc } from '@/utils/trpc';
// import OneSignal from 'react-onesignal';

export default function useLogin() {
  const { queryClient } = trpc.useContext();
  const { mutateAsync, ...mutation } = trpc.useMutation('auth.login', {
    onSuccess: async (res: any) => {
      if (res.user.user_mx_guid) {
        // await OneSignal.login(res.user.user_mx_guid);
      }
    },
  });

  const { refetch: fetchUserProfile } = trpc.useQuery(['user-profile.get'], { enabled: false });
  //const { refetch: refreshUser } = trpc.useQuery(['auth.me'], { enabled: false });

  return {
    ...mutation,
    login: async (...args: ArgumentTypes<typeof mutateAsync>) => {
      await clearAppState({ queryClient, executeQueryClientCache: false });
      await mutateAsync(...args);
      //await refreshUser();
      return fetchUserProfile();
    },
  };
}

import * as yup from 'yup';
import { EmailInput, PasswordInput } from '../../molecules/TextInput';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
// import Button from '../../atoms/Button';
// import Divider from '../../atoms/Divider';
import FormSubmitButton from '@/components/molecules/FormSubmitButton';
// import Image from 'next/image';
import Link from 'next/link';
// import Typography from '../../atoms/Typography';
import VerticalLayout from '@/components/layouts/VerticalLayout';
// import googleLogo from '@/images/google.svg';
// import * as amplitude from '@amplitude/analytics-browser';
import qs from 'qs';
import useLogin from '@/hooks/useLogin';
import { useRouter } from 'next/router';
import useSocialAuthPopup from '@/hooks/useSocialAuthPopup';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { useLoginContinue } from '@/hooks/amplitude/useLoginContinue';
import { useLoginGoogleauth0 } from '@/hooks/amplitude/useLoginGoogleauth0';
import { useLoginForgotPassword } from '@/hooks/amplitude/useLoginForgotPassword';
import Cookies from 'js-cookie';
import Typography from '@/components/atoms/@next/Typography';
import { MASTERCARD_OFFER_TERMS_URL } from '@/utils/constants';
import useContentfulLogin from '@/hooks/contenful/useContentfulLogin';
import LoadingSpinner from '@/components/atoms/LoadingSpinner';
export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormPageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  onSuccess: AnyFunction;
  onSignupLinkClick?: AnyFunction;
  onForgotPasswordLinkClick?: AnyFunction;
  error?: string;
}

export interface LoginFormContainerProps {
  onSuccess: AnyFunction;
  onSocialLoginClick: (connection: 'google-oauth2') => unknown;
  onSignupLinkClick?: AnyFunction;
  onForgotPasswordLinkClick?: AnyFunction;
  error?: string;
}

export interface LoginFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  onSubmit: (values: LoginFormValues) => any;
  onSocialLoginClick: (connection: 'google-oauth2') => unknown;
  onSignupLinkClick?: AnyFunction;
  onForgotPasswordLinkClick?: AnyFunction;
  error?: string;
  errorVariant?: 'error' | 'warning' | 'success';
  contentfulLogin: {
    title: string;
    email: string;
    password: string;
    Forgot: string;
    BtnSignIn: string;
    AccountInfo: string;
    BtnSignup: string;
    OfferTerms: string;
    validationSchema: {
      email: string;
      required: string;
      min: string;
    };
  };
}

export const LoginFormPageContainer: React.FC<LoginFormPageContainerProps> = ({
  onSuccess,
  error,
}) => {
  const { showPopup } = useSocialAuthPopup();
  const [socialAuthError, setSocialAuthError] = useState<string>();
  const { loginContinueTrack } = useLoginContinue();
  const { loginGoogleauth0Track } = useLoginGoogleauth0();

  return (
    <LoginFormContainer
      onSuccess={(onSuccessResponse) => {
        // amplitude.setUserId(onSuccessResponse.id);
        loginContinueTrack();
        onSuccess(onSuccessResponse);
      }}
      onSocialLoginClick={(connection) =>
        showPopup(connection, async (err, response) => {
          if (err) {
            const { error, description, errorDescription, error_description } = err;
            setSocialAuthError(description || errorDescription || error_description || error);
            return;
          }
          Cookies.set('linkrSession', response.accessToken!);
          loginGoogleauth0Track();
          await onSuccess();
        })
      }
      error={socialAuthError || error}
    />
  );
};

export const LoginFormModalContainer: React.FC<LoginFormPageContainerProps> = ({
  onSuccess,
  onSignupLinkClick,
  onForgotPasswordLinkClick,
  error,
}) => {
  const { showPopup } = useSocialAuthPopup();
  const [socialAuthError, setSocialAuthError] = useState<string>();
  const { loginContinueTrack } = useLoginContinue();
  const { loginGoogleauth0Track } = useLoginGoogleauth0();

  return (
    <LoginFormContainer
      onSuccess={(onSuccessResponse) => {
        loginContinueTrack();
        onSuccess(onSuccessResponse);
      }}
      onSocialLoginClick={(connection) =>
        showPopup(connection, async (err) => {
          if (err) {
            const { error, description, errorDescription, error_description } = err;
            setSocialAuthError(description || errorDescription || error_description || error);
            return;
          }

          loginGoogleauth0Track();
          await onSuccess();
        })
      }
      onSignupLinkClick={onSignupLinkClick}
      onForgotPasswordLinkClick={onForgotPasswordLinkClick}
      error={socialAuthError || error}
    />
  );
};

export const LoginFormContainer: React.FC<LoginFormContainerProps> = ({
  onSuccess,
  onSocialLoginClick,
  onSignupLinkClick,
  onForgotPasswordLinkClick,
  error,
}) => {
  const { login, error: loginError } = useLogin();
  const page = useContentfulLogin();

  if (!page) {
    return (
      <VerticalLayout className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </VerticalLayout>
    );
  }

  const {
    items: {
      title,
      email,
      password,
      Forgot,
      BtnSignIn,
      AccountInfo,
      BtnSignup,
      OfferTerms,
      validationSchema,
      errorSchema,
    },
  } = page;

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    try {
      const { data: user } = await login({ email, password });
      await onSuccess(user);
    } catch (error) {
      // noop: handled by useLogin hook
    }
  };

  return (
    <LoginForm
      onSubmit={onSubmit}
      error={errorSchema.data[loginError?.shape?.data.code!] || error}
      // error={loginError?.message || error}
      onSocialLoginClick={onSocialLoginClick}
      onSignupLinkClick={onSignupLinkClick}
      onForgotPasswordLinkClick={onForgotPasswordLinkClick}
      errorVariant={
        //@ts-ignore
        loginError?.data?.code === 'ERROR_CODE_EMAIL_NOT_VERIFIED' ? 'success' : 'error'
      }
      contentfulLogin={{
        title: title.text,
        email: email.text,
        password: password.text,
        Forgot: Forgot.text,
        BtnSignIn: BtnSignIn.text,
        AccountInfo: AccountInfo.text,
        BtnSignup: BtnSignup.text,
        OfferTerms: OfferTerms.text,
        validationSchema: {
          required: validationSchema.data.required,
          email: validationSchema.data.email,
          min: validationSchema.data.min,
        },
      }}
    />
  );
};

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  error,
  // onSocialLoginClick,
  onSignupLinkClick,
  onForgotPasswordLinkClick,
  errorVariant = 'error',
  contentfulLogin,
}) => {
  const router = useRouter();
  const [passVisible, setPassVisible] = useState(false);
  const { loginForgotPasswordTrack } = useLoginForgotPassword();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .email(contentfulLogin.validationSchema.email)
          .required(contentfulLogin.validationSchema.required),
        password: yup
          .string()
          .required(contentfulLogin.validationSchema.required)
          .min(8, contentfulLogin.validationSchema.min),
      })}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form className="flex w-full flex-col gap-6">
          <VerticalLayout>
            <Typography variant="heading" size="56px" className="!font-normal text-center">
              {contentfulLogin.title}
            </Typography>
            <div className="inline text-right">
              <EmailInput
                variant="rounded"
                label={contentfulLogin.email}
                name="email"
                placeholder={contentfulLogin.email}
                disabled={isSubmitting}
                autoComplete="username"
              ></EmailInput>
              <PasswordInput
                variant="rounded"
                label={contentfulLogin.password}
                name="password"
                disabled={isSubmitting}
                type={passVisible ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder={contentfulLogin.password}
                iconRight={passVisible ? faEyeSlash : faEye}
                onRightIconClick={() => setPassVisible(!passVisible)}
              ></PasswordInput>
              {!onForgotPasswordLinkClick && (
                <Link href="/forgot-password" className="text-right">
                  <div onClick={loginForgotPasswordTrack}>
                    <Typography
                      variant="body"
                      size="16px"
                      className="underline cursor-pointer font-medium !text-right"
                    >
                      {contentfulLogin.Forgot}
                    </Typography>
                  </div>
                </Link>
              )}
              {onForgotPasswordLinkClick && (
                <Link href="/forgot-password" className="text-right">
                  <div onClick={loginForgotPasswordTrack}>
                    <Typography
                      variant="body"
                      size="16px"
                      className="underline cursor-pointer font-medium !text-right"
                    >
                      {contentfulLogin.Forgot}
                    </Typography>
                  </div>
                </Link>
              )}
            </div>

            <FormSubmitButton
              loading={isSubmitting}
              disabled={!isValid}
              error={error}
              errorVariant={errorVariant}
            >
              {contentfulLogin.BtnSignIn}
            </FormSubmitButton>

            <div className="text-center font-medium">
              <Typography variant="body" size="16px">
                {contentfulLogin.AccountInfo}{' '}
              </Typography>
              {!onSignupLinkClick && (
                <Link href={`/signup${qs.stringify(router.query, { addQueryPrefix: true })}`}>
                  <Typography
                    variant="body"
                    size="16px"
                    className="underline text-sumawealth-red-80 !font-semibold cursor-pointer"
                  >
                    {contentfulLogin.BtnSignup}
                  </Typography>
                </Link>
              )}
              {onSignupLinkClick && (
                <div className="underline cursor-pointer" onClick={onSignupLinkClick}>
                  <Typography
                    variant="body"
                    size="16px"
                    className="underline text-sumawealth-red-80 !font-semibold cursor-pointer"
                  >
                    {contentfulLogin.BtnSignup}
                  </Typography>
                </div>
              )}
            </div>

            <div className="text-center font-medium text-sm">
              <Link href={MASTERCARD_OFFER_TERMS_URL} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="body"
                  size="16px"
                  className="underline text-sumawealth-red-80 !font-semibold cursor-pointer"
                >
                  {contentfulLogin.OfferTerms}
                </Typography>
              </Link>
            </div>
            {/* <div className="relative my-4">
              <Divider />
              <Typography
                variant="label"
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform bg-pampas px-4 text-center"
              >
                or
              </Typography>
            </div> */}
            {/* <Button
              variant="transparent"
              type="button"
              iconComponent={<Image src={googleLogo} width={24} height={24} alt="Google logo" />}
              disabled={isSubmitting}
              onClick={() => onSocialLoginClick('google-oauth2')}
            >
              Continue with Google
            </Button> */}
          </VerticalLayout>
        </Form>
      )}
    </Formik>
  );
};

import { LoginFormPageContainer } from '@/components/organisms/LoginForm/LoginForm';
import React from 'react';
import { useRouter } from 'next/router';
import useRemoveLocalStorage from '@/hooks/useRemoveLocalStorage';

interface LoginProps extends React.HTMLAttributes<HTMLDivElement> {}

const Login: React.FC<LoginProps> = () => {
  const router = useRouter();
  const { redirectTo, error } = router.query as { redirectTo?: string; error?: string };

  useRemoveLocalStorage({ enabled: true });

  const onSuccess = async () => {
    router.replace(redirectTo || '/sumaversity');
  };

  return <LoginFormPageContainer onSuccess={onSuccess} error={error} />;
};

export default Login;

import React, { ReactElement } from 'react';

import AuthTemplate from '@/components/templates/AuthTemplate';
import Head from 'next/head';
import Login from '@/components/pages/Login';
import { NextPageWithLayout } from './_app';
import { useRouter } from 'next/router';
import useUser from '@/hooks/useUser';
import { withTranslate } from '@/hoc/withTranslate';

const LoginPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { redirectTo } = router.query as { redirectTo?: string; error?: string };

  // Make sure to keep this client-side so we can statically render
  // this page!
  useUser({
    redirectTo: redirectTo || '/sumaversity',
    redirectIfFound: true,
  });

  return (
    <>
      <Head>
        <title>Login | Suma</title>
      </Head>

      <Login />
    </>
  );
};

LoginPage.getLayout = (page: ReactElement) => <AuthTemplate>{page}</AuthTemplate>;

export const getServerSideProps = withTranslate(async (context, ssg) => {
  if (context.req.cookies.appSession) {
    return {
      props: {
        trpcState: ssg.dehydrate(),
      },
      redirect: {
        destination: '/sumaversity',
        permanent: false,
      },
    };
  }

  return {
    props: {
      trpcState: ssg.dehydrate(),
    },
  };
}, 'Login');

export default LoginPage;
